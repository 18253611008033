import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { CardRedeem } from '@/core/domain/card-redeem/models/card-redemm.model';
import { CardRedeemRepository } from '@/core/domain/card-redeem/repositories/card-redeem.repository';
import { environment } from '@/env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    Observable,
    catchError,
    concat,
    filter,
    of,
    switchMap,
    throwError,
} from 'rxjs';
import { openModalError } from '../modal-error/store/modal-error.actions';

@Injectable({
    providedIn: 'root',
})
export class CardRedeemImplRepository extends CardRedeemRepository {
    constructor(
        private readonly http: HttpClient,
        private readonly authCheckLogin: CheckLoginUseCase,
    ) {
        super();
    }

    private store = inject(Store);
    private openLoginModalError(): void {
        this.store.dispatch(
            openModalError({
                data: {
                    isOpen: true,
                    description: '',
                    error: '',
                    redirectSSO: true,
                },
            }),
        );
    }

    override postCardRedeem(card: CardRedeem): Observable<any> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth?.accessToken) {
                    this.openLoginModalError();
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE LOGIC INTO A SERVICE
                const _headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    this.http.post(
                        environment.vgApi.BASE_HOST +
                            environment.vgApi.ENDPOINTS.redeem,
                        card,
                        { headers: _headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: any) => {
                        const configData = response;
                        return of(configData as any);
                    }),
                    catchError((error) => {
                        return of(error as any);
                    }),
                );
            }),
        );
    }

    override postCardRedeemB2B(card: CardRedeem): Observable<any> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth?.accessToken) {
                    this.openLoginModalError();
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE LOGIC INTO A SERVICE
                const _headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    this.http.post(
                        environment.vgApi.BASE_HOST +
                            environment.vgApi.ENDPOINTS.redeemB2B,
                        card,
                        { headers: _headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: any) => {
                        const configData = response;
                        return of(configData as any);
                    }),
                    catchError((error) => {
                        return of(error as any);
                    }),
                );
            }),
        );
    }

    override postCardRedeemMP(card: CardRedeem): Observable<any> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth?.accessToken) {
                    this.openLoginModalError();
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE LOGIC INTO A SERVICE
                const _headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    this.http.post(
                        environment.vgApi.BASE_HOST +
                            environment.vgApi.ENDPOINTS.redeemMP,
                        card,
                        { headers: _headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: any) => {
                        const configData = response;
                        return of(configData as any);
                    }),
                    catchError((error) => {
                        return of(error as any);
                    }),
                );
            }),
        );
    }
}
