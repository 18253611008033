import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import {
    OrdersCompensationModel,
    OrdersCompensationModelKeys,
} from '@/core/domain/orders-compensation/models/orders-compensation.model';
import { OrdersCompensationRepository } from '@/core/domain/orders-compensation/repositories/orders-compensation.repository';
import { environment as env } from '@/env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    EMPTY,
    Observable,
    catchError,
    concat,
    filter,
    of,
    switchMap,
    throwError,
} from 'rxjs';
import { openModalError } from '../modal-error/store/modal-error.actions';

@Injectable({
    providedIn: 'root',
})
export class OrdersCompensationImplRepository extends OrdersCompensationRepository {
    constructor(
        private http: HttpClient,
        private readonly authCheckLogin: CheckLoginUseCase,
    ) {
        super();
    }

    private store = inject(Store);
    private openLoginModalError(): void {
        this.store.dispatch(
            openModalError({
                data: {
                    isOpen: true,
                    description: '',
                    error: '',
                    redirectSSO: true,
                },
            }),
        );
    }

    override getOrdersCompensation<T>(
        config: OrdersCompensationModelKeys,
    ): Observable<T> {
        return this.authCheckLogin.execute().pipe(
            switchMap((auth) => {
                if (!auth?.accessToken) {
                    this.openLoginModalError();
                    throwError(() => new Error('User is not logged'));
                    return of();
                }

                // TODO: MOVE THIS LOGIC INTO A SERVICE
                const headers = new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${auth.accessToken}`,
                    'X-Channel': 'web',
                });

                return concat(
                    //TODO: agregar funcionalidad de variables de entornos para endpoints
                    this.http.get(
                        env.vgApi.BASE_HOST +
                            env.vgApi.ENDPOINTS.getCompensations,
                        { headers: headers },
                    ),
                ).pipe(
                    filter((response: any) => response != null),
                    switchMap((response: OrdersCompensationModel) => {
                        const configData = response[config];
                        return of(configData as T);
                    }),
                    catchError(() => EMPTY),
                );
            }),
        );
    }
}
