import { UrlService } from '@/common/url/url.service';
import { getLanguage } from '@/common/utils/strings/path.utils';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { CheckLoginExternalStatusUseCase } from '@/core/domain/auth/usecases/check-loginexternal-status.usecase';
import { LoginUseCase } from '@/core/domain/auth/usecases/login.usecase';
import { LogoutUseCase } from '@/core/domain/auth/usecases/logout.usecase';
import { GtmLoginIntent } from '@/core/domain/gtm/models/gtm-login-intent.model';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { TranslationsGetLanguageUseCase } from '@/core/domain/translation/usecases/translations-get-language.usecase';
import { TranslationsLoaderUseCase } from '@/core/domain/translation/usecases/translations-loader.usecase';
import { TranslationsTranslateRoutesUseCase } from '@/core/domain/translation/usecases/translations-translate-routes.usecase';
import { selectAuthValue } from '@/data/auth/store/auth.selectors';
import { selectLoadingUserState } from '@/data/auth/store/loading-user.selector';
import { selectRefreshData } from '@/data/auth/store/refresh-data.selector';
import { environment as env, environment } from '@/env/environment';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, filter, of, skip, switchMap, withLatestFrom } from 'rxjs';

@Component({
    selector: 'vg-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public isLoading = false;
    public isLogged = false;
    public user$ = new BehaviorSubject({ name: '' });
    public showSettings = false;
    public currentLanguage: string | null = null;
    public showLogout = false;
    @Input() maintenance_status?: boolean = false;


    constructor(
        private readonly store: Store,
        private readonly getLanguage: TranslationsGetLanguageUseCase,
        private readonly authLogin: LoginUseCase,
        private readonly checkLogin: CheckLoginUseCase,
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
        private readonly authLogout: LogoutUseCase,
        private readonly url: UrlService,
        private readonly transationsLoder: TranslationsLoaderUseCase,
        private readonly translateRoutes: TranslationsTranslateRoutesUseCase,
        private readonly checkLoginExternalStatusUseCase: CheckLoginExternalStatusUseCase,
    ) {
        this.getLanguage
            .execute()
            .subscribe(
                (response) => (this.currentLanguage = response.language),
            );

            this.store.select(selectRefreshData)
            .pipe(
                withLatestFrom( 
                    this.store.select(selectLoadingUserState)
                ),
                filter(([isRefresh, isLoadingUserState]) => isRefresh && !isLoadingUserState)
            )
            .subscribe(() => {
                if(!window.location.href.includes('dashboard'))
                {
                    this.checkLoginExternalStatusUseCase.execute().subscribe();
                }
            });
    }

    ngOnInit(): void {
        const auth$ = this.store.select(selectAuthValue);
        auth$.subscribe(() => this.initCheckAuthLoginState());
        this.initListenLoadingUser();
    }


    private initListenLoadingUser(): void {
        const loadingUser$ = this.store.select(selectLoadingUserState);
        loadingUser$.subscribe((response) => (this.isLoading = response));
    }

    private initCheckAuthLoginState() {
        this.checkLogin
            .execute()
            .pipe(
                switchMap((auth) => {
                    this.isLogged = auth?.isLogged ?? false;
                    this.isLoading = true;
                    if (!auth?.isLogged) {
                        this.isLoading = false;
                        this.isLogged = false;
                        this.user$.next({ name: '' });
                        return of(auth);
                    }
                    const name = auth.details?.firstName ?? '';
                    this.user$.next({ name });
                    this.isLogged = true;
                    this.isLoading = false;
                    return of(auth);
                }),
                catchError(() => {
                    this.isLoading = false;
                    this.isLogged = false;
                    return of(null);
                }),
            )
            .subscribe();
    }

    triggerLogin() {
        if (!this.isLogged && !this.maintenance_status) {
            this.authLogin.execute();
            this.gtmAction(true);
        } else if (!this.maintenance_status) {
            this.showLogoutSettings();
        }
    }

    onLogOut(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.gtmAction(false);
        this.authLogout.execute().subscribe(() => (this.showLogout = false));
    }

    public showUserSettings(): void {
        this.showSettings = !this.showSettings;
        if (this.showLogout) {
            this.showLogout = !this.showLogout;
        }
    }

    public showLogoutSettings(): void {
        this.showLogout = !this.showLogout;
        if (this.showSettings) {
            this.showSettings = !this.showSettings;
        }
    }

    public onClickOutside(): void {
        this.showSettings = false;
        this.showLogout = false;
    }

    public onClickChangeLang(toLang: 'es-mx' | 'en-us'): void {
        if (env.allowedLanguages.includes(toLang)) {
            const regExpValue = env.allowedLanguages.join('|');
            const languagePathRegExp = new RegExp(regExpValue);
            this.translateRoutes.execute().subscribe((responseRealPaths) => {
                responseRealPaths =
                    responseRealPaths.slice(-1) === '/'
                        ? responseRealPaths
                        : responseRealPaths + '/';
                this.transationsLoder
                    .execute({ lang: toLang, reload: false })
                    .pipe(
                        skip(1),
                        switchMap(() =>
                            this.url.format(
                                responseRealPaths.replace(
                                    getLanguage() + '/vivagift/',
                                    '',
                                ),
                                toLang,
                            ),
                        ),
                    )
                    .subscribe((url) => {
                        if (environment.production) {
                            url = '/' + toLang + '/vivagift' + url;
                        }
                        window.location.replace(
                            url.replace(languagePathRegExp, toLang),
                        );
                    });
            });
        }
    }

    gtmAction(login: boolean) {
        this.gtmDataLayer.execute<GtmLoginIntent>({
            event: 'ev_viva_gift_db',
            login: {
                status: login ? 'login_intent' : 'logout_intent',
            },
        });
    }
}
