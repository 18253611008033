import { checkScreenSize } from '@/common/helpers/screen-size.helper';
import { StepScrollService } from '@/common/step-scroll/step-scroll.service';
import { UrlService } from '@/common/url/url.service';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { LoginUseCase } from '@/core/domain/auth/usecases/login.usecase';
import { CardDesign } from '@/core/domain/configurations/models/configurations.model';
import {
    GtmEditGift,
    GtmEditGiftLabel,
} from '@/core/domain/gtm/models/gtm-edit-gift.model';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { PurchasePersistenceModel } from '@/core/domain/purchase-persistence/models/PurchasePersistence.model';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { SavePurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/save-purchase-persistence.usecase';
import { TranslationsGetLanguageUseCase } from '@/core/domain/translation/usecases/translations-get-language.usecase';
import { selectAuthValue } from '@/data/auth/store/auth.selectors';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of, switchMap } from 'rxjs';
import { ANIMATIONS_CARD } from '../card-amount/card-amount.component';

@Component({
    selector: 'vg-card-check-gift',
    templateUrl: './card-check-gift.component.html',
    styleUrls: ['./card-check-gift.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class CardCheckGiftComponent {
    public purchasePersistence: PurchasePersistenceModel | null = null;
    public isUserLogged = false;
    @Input() setCard: CardDesign | null = null;
    public lang = 'es-mx';
    private isMobileOrTablet?: boolean;
    constructor(
        private readonly authCheckLogin: CheckLoginUseCase,
        private readonly authLogin: LoginUseCase,
        private readonly getLanguage: TranslationsGetLanguageUseCase,
        private readonly getPurchasePersistence: GetPurchaseUseCase,
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
        private readonly router: Router,
        private readonly savePurchasePersistence: SavePurchaseUseCase,
        private readonly store: Store,
        protected readonly url: UrlService,
        private readonly stepScrollService: StepScrollService,
    ) {
        this.initPurchasePersistence();
        this.store.select(selectAuthValue).subscribe(() => {
            this.initCheckLogin();
        });
        this.getLanguage.execute().subscribe((lang) => {
            this.lang = lang.language;
        });
        this.isMobileOrTablet = checkScreenSize();
    }

    private initCheckLogin() {
        this.authCheckLogin
            .execute()
            .pipe(
                switchMap((auth) => {
                    if (auth && auth?.accessToken) {
                        this.isUserLogged = true;
                    }
                    return of(null);
                }),
            )
            .subscribe();
    }

    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe(
                (purchasePersistence) =>
                    (this.purchasePersistence = purchasePersistence),
            );
    }

    public goToLogin() {
        if (!this.isUserLogged) {
            this.authLogin.execute().subscribe();
        } else {
            const paymentPath = ['purchase', 'payment'];
            this.savePurchasePersistence
                .execute({
                    cardDesign: this.setCard!,
                })
                .pipe(switchMap(() => this.url.format(paymentPath.join('/'))))
                .subscribe((paymentPath) =>
                    {
                        this.router.navigateByUrl(paymentPath);
                        this.nextStep(paymentPath);
                    }
                );
        }
    }

    public nextStep(formattedUrl: string): void {
        this.stepScrollService.nextStep(
            formattedUrl,
            'scroll',
            this.isMobileOrTablet,
        );
    }

    gtmAction(label: GtmEditGiftLabel) {
        this.gtmDataLayer.execute<GtmEditGift>({
            event: 'ev_ux_viva_gift_edit',
            category: 'ux_viva_gift',
            action: 'edit_gift',
            label: label,
        });
    }
}
