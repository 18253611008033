import { createReducer, on } from '@ngrx/store';
import { updateCatchErrorRestore } from './error-restore.actions';

export const updateCatchErrorRestoreReducer = (
    _: boolean,
    { value }: { value: boolean },
): boolean => value;

export const CatchErrorReducer = createReducer(
    false,
    on(updateCatchErrorRestore, updateCatchErrorRestoreReducer),
);
