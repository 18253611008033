import { TranslateService } from '@ngx-translate/core';

export function translateError(
    translate: TranslateService,
    errorCode: string,
    emailInvalidRecipient = '',
): { title: string; text: string; btnTitle: string } {
    let title = translate.instant('vg_errors_code_' + errorCode + '_title');
    let text = translate.instant('vg_errors_code_' + errorCode + '_text');
    let btnTitle = translate.instant(
        'vg_errors_code_' + errorCode + '_button-title',
    );

    if (errorCode === 'INVALID_RECIPIENT') {
        text = text.replace('${email}', emailInvalidRecipient);
    }

    if (title === 'vg_errors_code_' + errorCode + '_title') {
        title = translate.instant(
            'vg_errors_code_GENERIC_VALIDATION_ERROR_title',
        );
    }
    if (text === 'vg_errors_code_' + errorCode + '_text') {
        text = translate.instant(
            'vg_errors_code_GENERIC_VALIDATION_ERROR_text',
        );
    }
    if (btnTitle === 'vg_errors_code_' + errorCode + '_button-title') {
        btnTitle = translate.instant(
            'vg_errors_code_GENERIC_VALIDATION_ERROR_button-title',
        );
    }

    return { title, text, btnTitle };
}
