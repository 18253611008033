<div class="contenedor-compensaciones">
    <ng-container *ngIf="!detailShowed">
        <ng-container
            *ngFor="
                let card of cardsReceived
                    | slice
                        : (page - 1) * numberCardsToShow
                        : page * numberCardsToShow
            "
        >
            <vg-card>
                <div class="col-12 compensaciones d-flex flex-column">
                    <div>
                        <div class="head d-flex justify-content-between">
                            <div
                                [ngClass]="{
                                    'mb-20': cardType(card) !== 'compensations'
                                }"
                                class="w-100"
                            >
                                <h3
                                    [ngClass]="{
                                        'title-head': true,
                                        'mb-4': card.gifter === null
                                    }"
                                >
                                    {{ card.operation ? '' : 'Viva Gift' }}
                                    {{ card.amount | formatNumber }}
                                    <span>{{ card.currency ? card.currency : 'MXN' }}</span>
                                </h3>
                                <div class="row align-items-start mx-0">
                                    <div class="col-12 col-lg-8 px-0">
                                        <span
                                            *ngIf="
                                                card.gifter || card.recipient
                                            "
                                            class="desc-head d-inline no-wrap"
                                        >
                                            {{
                                                cardType(card) === 'orders'
                                                    ? ('vg_dashboard_gifts_card_giftcard_span_to'
                                                          | translate) +
                                                      ' ' +
                                                      card.recipient
                                                    : cardType(card) === 'gifts'
                                                      ? ('vg_dashboard_gifts_card_giftcard_span_from'
                                                            | translate) +
                                                        ' ' +
                                                        (card.gifter ||
                                                            card.recipient)
                                                      : compensationType(
                                                              card.code
                                                          ) === 'MP'
                                                        ? ('vg_dashboard_card_status_span_compensation-type-MP'
                                                          | translate)
                                                        : compensationType(
                                                                card.code
                                                            ) === 'SR'
                                                          ? ('vg_dashboard_card_status_span_compensation-type-SR'
                                                            | translate)
                                                          : compensationType(
                                                              card.id
                                                          ) === 'CG' 
                                                          ? ('vg_dashboard_card_status_span_compensation-type-CG' 
                                                          | translate) 
                                                          : ''
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="card && card.pnr !== undefined"
                                        class="col-0 col-sm-5 col-lg-2 d-none d-lg-block pl-3 text-center"
                                    >
                                        <p class="desc-head d-inline pnr">
                                            {{
                                                card &&
                                                card.pnr !== null &&
                                                card.pnr !== ''
                                                    ? card.pnr
                                                    : '--'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <vg-button
                                *ngIf="typeDetail !== 'compensation'"
                                [onlyIcon]="true"
                                [icon]="'next-arrow-dark'"
                                [style]="'link'"
                                [href]="urlDetail | formatUrl | async"
                                [data]="card"
                                [vgOnClickSendTag]="{
                                    event: 'ev_viva_gift_lp',
                                    category: 'viva_gift_my_transactions',
                                    action: 'buy_vivagift'
                                }"
                                (click)="redirectDetails(cardType(card))"
                                class="d-none d-md-block arrow-detail-tb"
                            ></vg-button>
                            <vg-button
                                *ngIf="typeDetail === 'compensation'"
                                [onlyIcon]="true"
                                [icon]="'next-arrow-dark'"
                                [style]="'link'"
                                (click)="redirectCompensation()"
                                class="d-none d-md-block arrow-detail-tb"
                            ></vg-button>
                        </div>
                        <div
                            class="d-flex align-items-center justify-content-between conarrow info"
                            *ngIf="cardType(card) === 'compensations'"
                        >
                            <div class="mb-20">
                                <vg-badge
                                    [style]="
                                        status[
                                            card.operation
                                                ? card.operation
                                                : 'default'
                                        ].class
                                    "
                                    >{{
                                        status[
                                            card.operation
                                                ? card.operation
                                                : 'default'
                                        ].text
                                            ? status[
                                                  card.operation
                                                      ? card.operation
                                                      : 'default'
                                              ].text
                                            : status['default'].text
                                    }}</vg-badge
                                >
                            </div>
                        </div>
                        <div class="info d-flex align-items-end">
                            <div
                                class="col-6 {{
                                    cardType(card) === 'gifts' ? 'col-sm-4' : ''
                                }} p-0"
                                [ngClass]="{
                                    'col-lg-3': cardType(card) === 'orders',
                                    'col-lg-4': cardType(card) !== 'orders'
                                }"
                            >
                                <dl class="mb-0">
                                    <dt class="text-fecha">
                                        <ng-template
                                            [ngIf]="cardType(card) === 'gifts'"
                                        >
                                            {{
                                                'vg_dashboard_gifts_card_giftcard_dt_delivery-date'
                                                    | translate
                                            }}
                                        </ng-template>
                                        <ng-template
                                            [ngIf]="cardType(card) === 'orders'"
                                        >
                                            {{
                                                'vg_dashboard_gifts_card_giftcard_dt_purchase-date'
                                                    | translate
                                            }}
                                        </ng-template>
                                        <ng-template
                                            [ngIf]="
                                                cardType(card) ===
                                                'compensations'
                                            "
                                        >
                                            {{
                                                'vg_dashboard_gifts_card_giftcard_dt_activation-date'
                                                    | translate
                                            }}
                                        </ng-template>
                                    </dt>
                                    <dd class="desc-fecha">
                                        {{
                                            getFormattedActivationDate(
                                                card.activationDate ||
                                                    card.creationDate ||
                                                    card.date
                                            )
                                        }}
                                    </dd>
                                </dl>
                            </div>
                            <div
                                *ngIf="cardType(card) === 'orders'"
                                [ngClass]="{
                                    'col-lg-3': cardType(card) === 'orders',
                                    'col-lg-4': cardType(card) !== 'orders'
                                }"
                                class="col-6 col-sm-4 p-0"
                            >
                                <dl class="mb-0">
                                    <dt class="text-fecha">
                                        {{
                                            'vg_landing_dashboard_gifts_item-date-send'
                                                | translate
                                        }}
                                    </dt>
                                    <dd class="desc-fecha">
                                        {{
                                            getFormattedActivationDate(
                                                card.deliveryDate
                                            )
                                        }}
                                    </dd>
                                </dl>
                            </div>
                            <div
                                class="p-0"
                                [class.col-6]="
                                    cardType(card) === 'orders' ||
                                    cardType(card) === 'gifts' ||
                                    cardType(card) === 'compensations'
                                "
                                [class.col-lg-3]="cardType(card) === 'orders'"
                                [class.mt-fv]="cardType(card) === 'orders'"
                                [class.col-sm-4]="cardType(card) === 'gifts'"
                                [class.col-lg-4]="
                                    cardType(card) === 'gifts' ||
                                    cardType(card) === 'compensations'
                                "
                                [class.d-flex]="cardType(card) === 'gifts'"
                                [class.d-sm-block]="cardType(card) === 'gifts'"
                                [class.justify-content-end]="
                                    cardType(card) === 'gifts'
                                "
                                [class.justify-content-sm-start]="
                                    cardType(card) === 'gifts'
                                "
                            >
                                <dl class="mb-0">
                                    <dt class="text-fecha">
                                        {{
                                            'vg_dashboard_gifts_card_giftcard_dt_expiration-date'
                                                | translate
                                        }}
                                    </dt>
                                    <dd
                                        *ngIf="cardType(card) !== 'orders'"
                                        class="desc-fecha"
                                    >
                                        {{
                                            isValidDate(getEndDate(card))
                                                ? getFormattedActivationDate(
                                                      getEndDate(card)
                                                  )
                                                : getEndDate(card)
                                        }}
                                    </dd>
                                    <dd
                                        *ngIf="cardType(card) === 'orders'"
                                        class="desc-fecha"
                                    >
                                        {{
                                            getFormattedActivationDate(
                                                getEndDateOrders(
                                                    card.creationDate
                                                )
                                            )
                                        }}
                                    </dd>
                                </dl>
                            </div>

                            <div
                                *ngIf="card && card.pnr !== undefined"
                                class="col-6 d-lg-none mt-3 p-0"
                            >
                                <p class="desc-head d-inline pnr">
                                    {{
                                        card &&
                                        card.pnr !== null &&
                                        card.pnr !== ''
                                            ? card.pnr
                                            : '--'
                                    }}
                                </p>
                            </div>

                            <div
                                class="col-4 d-none d-sm-block d-lg-none mt-3 p-0"
                            >
                                <vg-badge [style]="status[card.status].class">{{
                                    status[card.status].text
                                }}</vg-badge>
                            </div>

                            <div class="col-4 d-sm-none mt-3 p-0">
                                <vg-badge [style]="status[card.status].class">{{
                                    status[card.status].text
                                }}</vg-badge>
                            </div>

                            <div
                                class="col-2 d-flex justify-content-end d-md-none mt-3 p-0"
                            >
                                <vg-button
                                    *ngIf="typeDetail === 'compensation'"
                                    [onlyIcon]="true"
                                    [icon]="'next-arrow-dark'"
                                    [style]="'link'"
                                    (click)="redirectCompensation()"
                                    class="d-md-none arrow-detail-tb arrow-detail-tb-sm"
                                ></vg-button>
                                <vg-button
                                    *ngIf="cardType(card) === 'orders'"
                                    [onlyIcon]="true"
                                    [icon]="'next-arrow-dark'"
                                    [style]="'link'"
                                    [href]="urlDetail | formatUrl | async"
                                    [data]="card"
                                    class="d-md-none arrow-detail-tb {{
                                        cardType(card) === 'gifts'
                                            ? 'd-sm-none'
                                            : ''
                                    }}"
                                ></vg-button>
                            </div>

                            <div
                                *ngIf="cardType(card) === 'gifts'"
                                class="col-6 d-flex d-sm-none mt-3 p-0 justify-content-end"
                            >
                                <vg-button
                                    *ngIf="cardType(card) === 'gifts'"
                                    [onlyIcon]="true"
                                    [icon]="'next-arrow-dark'"
                                    [style]="'link'"
                                    [href]="urlDetail | formatUrl | async"
                                    [data]="card"
                                    class="btn-link-gifts-sm d-md-none arrow-detail-tb {{
                                        cardType(card) === 'gifts'
                                            ? 'd-sm-none'
                                            : ''
                                    }}"
                                ></vg-button>
                            </div>

                            <div
                                class="mt-pill mt-lg-0 p-0 {{
                                    cardType(card) === 'compensations'
                                        ? 'col-12 col-md-4 mt-20'
                                        : cardType(card) === 'gifts'
                                          ? 'col-12 col-sm-4'
                                          : cardType(card) === 'orders'
                                            ? 'col-12'
                                            : ''
                                }}"
                                [ngClass]="{
                                    'col-lg-3': cardType(card) === 'orders',
                                    'col-lg-4':
                                        cardType(card) !== 'orders' &&
                                        cardType(card) !== 'compensations'
                                }"
                            >
                                <div
                                    class="d-none d-md-flex align-items-end h-100 justify-content-end justify-content-lg-between"
                                >
                                    <vg-badge
                                        class="d-none d-lg-block"
                                        [style]="status[card.status].class"
                                        >{{
                                            status[card.status].text
                                        }}</vg-badge
                                    >
                                    <vg-button
                                        *ngIf="cardType(card) === 'orders'"
                                        [onlyIcon]="true"
                                        [icon]="'next-arrow-dark'"
                                        [style]="'link'"
                                        [href]="urlDetail | formatUrl | async"
                                        [data]="card"
                                        class="d-md-none arrow-detail-tb {{
                                            cardType(card) === 'gifts'
                                                ? 'd-sm-none'
                                                : ''
                                        }}"
                                    ></vg-button>
                                    <vg-button
                                        *ngIf="typeDetail === 'compensation'"
                                        [onlyIcon]="true"
                                        [icon]="'next-arrow-dark'"
                                        [style]="'link'"
                                        (click)="redirectCompensation()"
                                        class="d-md-none arrow-detail-tb"
                                    ></vg-button>
                                </div>
                            </div>

                            <div
                                *ngIf="cardType(card) === 'gifts'"
                                class="mt-1 mt-lg-0 p-0 col-12 d-none d-sm-block d-lg-none"
                            >
                                <div
                                    class="d-flex justify-content-end align-items-end h-100"
                                >
                                    <vg-button
                                        *ngIf="cardType(card) === 'gifts'"
                                        [onlyIcon]="true"
                                        [icon]="'next-arrow-dark'"
                                        [style]="'link'"
                                        [href]="urlDetail | formatUrl | async"
                                        [data]="card"
                                        class="d-md-none arrow-detail-tb"
                                    ></vg-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </vg-card>
        </ng-container>

        <div *ngIf="cardsReceived.length > 3 && pagination">
            <ngb-pagination
                [collectionSize]="cardsReceived.length"
                [pageSize]="numberCardsToShow"
                [(page)]="page"
                class="d-flex justify-content-center pagination-viva"
                aria-label="cards pagination"
            >
                <ng-template ngbPaginationPrevious>
                    <img
                        [src]="imageArrowLeft | cdn"
                        alt="arrow left"
                        width="15px"
                        height="27px"
                    />
                </ng-template>
                <ng-template ngbPaginationNext>
                    <img
                        [src]="imageArrowRight | cdn"
                        alt="arrow right"
                        width="15px"
                        height="27px"
                    />
                </ng-template>
                <ng-template ngbPaginationNumber let-page>{{
                    page
                }}</ng-template>
            </ngb-pagination>
        </div>
    </ng-container>
</div>
