import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './state.actions';

export const INITIAL_STATE = '';

export const _stateReducer = createReducer(
    INITIAL_STATE,
    on(actions.setState, (_, { state }): string => state),
    on(actions.resetState, (): string => INITIAL_STATE),
);

export const StateReducer = (state: string | undefined, action: Action) => {
    return _stateReducer(state, action);
};
