<h1 class="card-confirmation-title fw-bold">
    {{ 'vg_landing_purchase_confirmation_h1-title' | translate }}
</h1>
<div class="gc-confirmation">
    <vg-card>
        <div class="container-all">
            <div class="user-info d-flex flex-column">
                <div class="row">
                    <dl class="col-6 col-lg-4 flex-column d-flex">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_confirmation-receiver-label'
                                    | translate
                            }}
                        </dt>
                        <dd class="subtitle text font-family-lato">
                            {{ card.recipient }}
                        </dd>
                    </dl>
                    <dl class="col-6 col-lg-4 flex-column d-flex">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_confirmation-mail-receiver-label'
                                    | translate
                            }}
                        </dt>
                        <dd class="subtitle text font-family-lato">
                            {{ card.recipientEmail }}
                        </dd>
                    </dl>
                    <dl class="col-6 col-lg-4 flex-column d-flex">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_confirmation-date-send-label'
                                    | translate
                            }}
                        </dt>
                        <dd class="subtitle text font-family-lato">
                            {{ card.deliveryDate | date: 'mediumDate' }}
                        </dd>
                    </dl>
                    <dl class="col-6 col-lg-4 flex-column d-flex">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_confirmation-date-expiration-label'
                                    | translate
                            }}
                        </dt>
                        <dd class="subtitle text font-family-lato">
                            {{ card.expirationDate | date: 'mediumDate' }}
                        </dd>
                    </dl>
                    <dl class="col-6 col-lg-4 flex-column d-flex">
                        <dt class="title">
                            {{
                                'vg_landing_purchase_confirmation-status-label'
                                    | translate
                            }}
                        </dt>
                        <vg-badge [style]="status[card.status].class">{{
                            status[card.status].text
                        }}</vg-badge>
                    </dl>
                </div>
                <div class="row">
                    <div class="col-12 msg font-family-lato">
                        <p>
                            {{
                                'vg_landing_purchase_confirmation-information-text'
                                    | translate
                            }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="order-info">
                <h2 class="o-title fw-bold">
                    {{
                        'vg_landing_purchase_confirmation_order_info_h2-title'
                            | translate
                    }}
                </h2>
                <h3 class="o-subtitle fw-bold">
                    {{
                        'vg_landing_purchase_confirmation_order_info_h3-subtitle'
                            | translate
                    }}
                </h3>
                <div class="order-row-container d-flex flex-column">
                    <div class="row">
                        <dl class="col-6 col-lg-4 flex-column d-flex">
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_info_amount-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="subtitle text font-family-lato">
                                {{ card.amount | formatNumber }}
                            </dd>
                        </dl>
                        <dl class="col-6 col-lg-4 flex-column d-flex">
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_info_order-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="subtitle text font-family-lato">
                                #{{ card.orderId | uppercase }}
                            </dd>
                        </dl>
                        <dl class="col-6 col-lg-4 flex-column d-flex">
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_info_code-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="subtitle text font-family-lato">
                                {{ card.code | mask: 'AAAAA-AAAAA-AAAA' }}
                            </dd>
                        </dl>
                        <dl
                            class="col-6 col-lg-4 flex-column d-sm-none d-md-block d-lg-none d-xl-none"
                        >
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_date-expiration-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="subtitle text font-family-lato">
                                {{ card.expirationDate | date: 'mediumDate' }}
                            </dd>
                        </dl>
                    </div>
                    <div class="row">
                        <dl
                            class="col-6 col-lg-4 flex-column d-none d-sm-flex d-md-none"
                        >
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_date-expiration-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="text font-family-lato">
                                {{ card.expirationDate | date: 'mediumDate' }}
                            </dd>
                        </dl>
                    </div>
                </div>
                <h4 class="o-subtitle">
                    {{
                        'vg_landing_purchase_confirmation_order_info_method-pay-h4-subtitle'
                            | translate
                    }}
                </h4>
                <div class="order-row-container d-flex flex-column">
                    <div class="row">
                        <dl class="col-12 col-lg-4 flex-column d-flex">
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_card-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="subtitle text font-family-lato">
                                {{
                                    'vg_purchase_purchase_confirmation_dd_masked-card'
                                        | translate
                                }}
                                {{ card.cardLastFour }}
                            </dd>
                        </dl>
                        <dl class="col-12 col-lg-8 flex-column d-flex">
                            <dt class="title">
                                {{
                                    'vg_landing_purchase_confirmation_order_info_card-title'
                                        | translate
                                }}
                            </dt>
                            <dd class="subtitle text font-family-lato">
                                {{ card.purchaseName }}
                            </dd>
                            <dd class="subtitle text font-family-lato">
                                {{ card.purchaseAddress }}
                            </dd>
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    </vg-card>
</div>
