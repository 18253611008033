import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class StepScrollService {
    constructor(private router: Router) {}

    public nextStep(
        formattedUrl: string,
        scrollToId: string,
        isMobileOrTablet?: boolean,
        scrollToTop?: boolean
    ): void {
        this.router.navigate([formattedUrl]).then(() => {
            if (isMobileOrTablet) {
                setTimeout(() => {
                    const element = document.getElementById(scrollToId);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                    if (scrollToTop) {
                        window.scrollBy(0, -10);
                    }
                });
            }
        });
    }
}
