import { translateError } from '@/common/helpers/error-translation.helper';
import { UrlService } from '@/common/url/url.service';
import { GtmReedemCode } from '@/core/domain/gtm/models/gtm-reedem-code.models';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import {
    LoderCardRedeem,
    PostCardRedeemB2BUseCase,
    PostCardRedeemMPUseCase,
    PostCardRedeemUseCase,
} from '../../../../core/domain/card-redeem/usecases/postCardRedeem-loader.usecase';
import { ErrorCode, setErrorCodes } from './error-codes';
@Component({
    selector: 'vg-card-redeem',
    templateUrl: './card-redeem.component.html',
    styleUrls: ['./card-redeem.component.scss'],
})
export class CardRedeemComponent {
    public cardRedeemForm: FormGroup;
    @Input()
    title = '';
    @Input()
    subtitle = '';
    @Input()
    btnTitle = '';
    @Input()
    image = '';
    @Input()
    btnCompensations = false;
    errorCodes = setErrorCodes(this.translate);
    errorRedeemValues: ErrorCode = { title: '', text: '', btnTitle: '' };
    enabledBtnRedeem: boolean;
    regexCardNumber: string;
    modalError: boolean;
    modalSuccess: boolean;
    isLoadingBtn: boolean;
    codeCard: string;
    titleError: string;
    textError: string;
    btnTitleError: string;
    isCompensation: boolean;
    expirationDateFormattedSuccess: string;
    emailInvalidRecipent = '';
    public urlRedirect = 'dashboard/home';
    constructor(
        private translate: TranslateService,
        private readonly formBuilder: FormBuilder,
        private readonly postCardRedeemUseCase: PostCardRedeemUseCase,
        private readonly postCardRedeemB2BUseCase: PostCardRedeemB2BUseCase,
        private readonly postCardRedeemMPUseCase: PostCardRedeemMPUseCase,
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
        private readonly url: UrlService,
        private readonly router: Router,
    ) {
        this.enabledBtnRedeem = false;
        this.regexCardNumber = '/^[A-Z0-9]{15}$/';
        this.modalError = false;
        this.modalSuccess = false;
        this.isLoadingBtn = false;
        this.codeCard = '';
        this.titleError = '';
        this.textError = '';
        this.btnTitleError = '';
        this.isCompensation = false;
        this.expirationDateFormattedSuccess = '';
        this.cardRedeemForm = this.formBuilder.group({
            cardNumber: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern('^[A-Z0-9]{14,15}$'),
                ]),
            ],
        });
    }

    receiveClose($event: boolean) {
        this.modalError = $event;
    }

    receiveCloseSuccess($event: boolean) {
        if ($event === true) {
            window.open(
                this.translate.instant(
                    'vg_landing_dashboard_url_payment-methods',
                ),
                '_blank',
            );
        }
        if ($event === false) {
            this.modalSuccess = false;
            this.url
                .format(this.urlRedirect)
                .subscribe((url) => this.router.navigateByUrl(url));
        }
    }

    onChangeCardNumber(event: any) {
        let inputValue: string = event.target.value;
        inputValue = inputValue.replace(/-/g, '');
        inputValue = inputValue.toUpperCase();

        this.cardRedeemForm.patchValue(
            { cardNumber: inputValue },
            { emitEvent: false },
        );

        const cardNumberControl = this.cardRedeemForm.get('cardNumber');
        if (
            cardNumberControl &&
            cardNumberControl.valid &&
            !this.isLoadingBtn
        ) {
            this.enabledBtnRedeem = true;
        } else {
            this.enabledBtnRedeem = false;
        }
    }

    async onClickRedeem() {
        if (this.enabledBtnRedeem) {
            this.enabledBtnRedeem = false;
            this.isLoadingBtn = true;

            const cardNumberControlValue =
                this.cardRedeemForm.get('cardNumber')?.value;

            try {
                await this.submitRedeemCard({ cardId: cardNumberControlValue });
            } finally {
                this.isLoadingBtn = false;
                const cardNumberControl = this.cardRedeemForm.get('cardNumber');
                this.enabledBtnRedeem = !!(
                    cardNumberControl && cardNumberControl.valid
                );
            }
        }
    }

    formatExpirationDate(expirationDate: string): string {
        const date = new Date(expirationDate);
        const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        };
        return date.toLocaleDateString('es-ES', options);
    }

    private async submitRedeemCard(cardRedeem: LoderCardRedeem): Promise<void> {
        const isB2B = cardRedeem.cardId.startsWith('BG');
        const isMP = cardRedeem.cardId.startsWith('MP');
        const isSR = cardRedeem.cardId.startsWith('SR');
        const isVB = cardRedeem.cardId.startsWith('VB');

        try {
            if (isB2B) {
                await firstValueFrom(
                    this.postCardRedeemB2BUseCase.execute(cardRedeem),
                ).then((data) => this.handleResponse(data));
            } else if (isMP || isSR) {
                await firstValueFrom(
                    this.postCardRedeemMPUseCase.execute(cardRedeem),
                ).then((data) => this.handleResponse(data));
            } else {
                await firstValueFrom(
                    this.postCardRedeemUseCase.execute(cardRedeem),
                ).then((data) => this.handleResponse(data));
            }
        } catch (error) {
            this.setErrorCode();
            this.modalError = true;
            this.gtmErrorAction();
        }
    }

    private handleResponse(data: any) {
        if (data.error) {
            const errorCode = data.error.code;
            if (errorCode === 'INVALID_RECIPIENT') {
                this.emailInvalidRecipent = data.error.data.email;
            }
            this.setErrorCode(errorCode);
            this.modalError = true;
            this.gtmErrorAction();
        } else if (data.type === 'SUCCESS' && data.action === 'CONTINUE') {
            if (data.data.expirationDate) {
                this.expirationDateFormattedSuccess = this.formatExpirationDate(
                    data.data.expirationDate,
                );
            }
            this.isCompensation = data.action === 'CONTINUE';
            this.modalSuccess = true;
            this.urlRedirect = this.isCompensation
                ? 'dashboard/compensations'
                : 'dashboard/gifts';
            this.gtmAction(
                'successful',
                data.data.cardStatus,
                data.data.expirationDate,
                data.data.amount,
            );
        } else {
            this.setErrorCode();
            this.modalError = true;
            this.gtmErrorAction();
        }
    }

    private setErrorCode(errorCode = 'DEFAULT_ERROR'): void {
        this.errorRedeemValues = translateError(
            this.translate,
            errorCode,
            this.emailInvalidRecipent,
        );
    }

    gtmErrorAction() {
        this.gtmAction('unsuccessful', '', '', '');
    }

    gtmAction(
        label: string,
        status: string,
        expirationDate: string,
        amount: string,
    ) {
        this.gtmDataLayer.execute<GtmReedemCode>({
            event: 'ev_viva_gift_redeem_card',
            category: 'viva_gift',
            action: 'redeem_card',
            label: label,
            viva_gift: {
                status: status,
                expiration_date:
                    expirationDate === ''
                        ? ''
                        : DateTime.fromISO(expirationDate).toFormat(
                              'dd/MM/yyyy',
                          ), // 01/01/2023
                amount: '' + amount,
            },
        });
    }
}
