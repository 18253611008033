<div class="card-message">
    <h2 class="title-h2">{{ 'vg_purchase_message_h2_title' | translate }}</h2>
    <div class="x-container" [@fadeInOut]>
        <vg-card>
            <form [formGroup]="messageForm">
                <div class="input-message">
                    <vg-input
                        [placeholder]="
                            'vg_purchase_message_vg_input_message_placeholder'
                                | translate
                        "
                        [label]="
                            'vg_purchase_message_vg_input_message_label'
                                | translate
                        "
                        class="d-grid"
                        [isTextarea]="true"
                        formControlName="message"
                        [flowPage]="'purchase_message'"
                        [(ngModel)]="messageValue"
                        [maxlength]="'45'"
                        (click)="clickMessage()"
                    ></vg-input>
                    <vg-swiper-message
                        [ngClass]="showSwiper()"
                        (newItemEvent)="messageSwiper($event)"
                    ></vg-swiper-message>
                </div>
                <div class="input-name mb-1">
                    <vg-input
                        [placeholder]="
                            'vg_purchase_message_vg_input_from_placeholder'
                                | translate
                        "
                        [label]="
                            'vg_purchase_message_vg_input_from_label'
                                | translate
                        "
                        class="d-grid"
                        [maxlength]="'45'"
                        formControlName="from"
                        [flowPage]="'purchase_message'"
                        (click)="clickName()"
                    ></vg-input>
                </div>
                <p>
                    {{ 'vg_purchase_message_p_instructions' | translate }}
                </p>
                <ng-container
                    *ngIf="
                        url.format('purchase/custom') | async as formattedUrl
                    "
                    ><vg-button
                        [type]="'submit'"
                        class="d-grid"
                        (click)="onClickSubmitMessage(formattedUrl)"
                        [disabled]="!messageForm.valid"
                        [style]="'success-outline'"
                        >{{
                            'vg_purchase_message_btn-next' | translate
                        }}</vg-button
                    >
                </ng-container>
            </form>
        </vg-card>
    </div>
</div>
