<div class="swiper-button-prev-outside">
    <img
        [src]="'vg/images/arrow_green.svg' | cdn"
        alt="arrow_green"
        class="card-redeem-img"
    />
</div>

<vg-swiper
    *ngIf="showSwiper"
    [loop]="true"
    [centeredSlides]="true"
    [slidesPerView]="3"
    [spaceBetween]="'14px'"
    [swiperButtonNext]="'.swiper-button-next-outside'"
    [swiperButtonPrev]="'.swiper-button-prev-outside'"
    [showPagination]="false"
    [showBtnNavigation]="false"
    [breakpoints]="breakpoints"
    [initialSlide]="initialSlide"
    (currentSlideIndex)="handleImageChange($event)"
>
    <div
        class="swiper-slide"
        *ngFor="let card of cards$ | async; trackBy: identify; let i = index"
        [ngClass]="{ centered: i === currentSlideIndex }"
    >
        <img [src]="card.resource" alt="{{ card.title }}" />
    </div>
</vg-swiper>

<div class="swiper-button-next-outside">
    <img
        [src]="'vg/images/arrow_green.svg' | cdn"
        alt="arrow_green"
        class="card-redeem-img"
    />
</div>
