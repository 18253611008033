import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { CardRedeemDataModule } from '@/data/cardRedeem/card-redeem-data.module';
import { CardDesignsDataModule } from '@/data/configurations/configuration-data.module';
import { ConfigurationReducer } from '@/data/configurations/store';
import { CurrentProgressReducer } from '@/data/current-progress/store';
import { GtmDataModule } from '@/data/gtm/gtm-data.module';
import { ModalErrorDataModule } from '@/data/modal-error/modal-error-data.module';
import { ModalErrorReducer } from '@/data/modal-error/store/modal-error.reducer';
import { OrdersDataModule } from '@/data/orders/orders-data.module';
import { PurchasePersistenceReducer } from '@/data/purchase-persistence/store';
import { TranslationReducer } from '@/data/translation/store';
import { TranslationsDataModule } from '@/data/translation/translations-data.module';
import { CounterReducer } from '../data/counter/store';
import { hydrationMetaReducer } from '../data/persistence/hydration.reducer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

import { OrdersCompensationDataModule } from '@/data/orders-compensation/orders-compensation-data.module';
import { OrdersReceivedDataModule } from '@/data/orders-received/orders-received-data.module';

import { GlobalErrorHandler } from '@/common/error-handlers/global-error-handler';
import { GlobalHttpErrorInterceptor } from '@/common/error-handlers/global-error.interceptor';
import { AuthInterceptorProvider } from '@/common/http/auth.interceptor';
import { AuthDataModule } from '@/data/auth/auth-data.module';
import { AuthReducer } from '@/data/auth/store/auth.reducer';
import { CatchErrorReducer } from '@/data/auth/store/catchErrorRestore/error-restore.reducer';
import { FromSSOReducer } from '@/data/auth/store/from-sso.reducer';
import { LoadingUserReducer } from '@/data/auth/store/loading-user.reducer';
import { RefreshDataReducer } from '@/data/auth/store/refresh-data.reducer';
import { StateReducer } from '@/data/auth/store/state.reducer';
import { EtokenReducer } from '@/data/auth/store/vivaUserEtoken/etoken.reducer';
import { vivaUserEsidMetaReducer } from '@/data/persistence/viva-user-esid.reducer';
import { vivaUserEtokenMetaReducer } from '@/data/persistence/viva-user-etoken';
import { vivaUserTokenExpirationMetaReducer } from '@/data/persistence/viva-user-token-expires.reducer';
import { vivaUserTokenMetaReducer } from '@/data/persistence/viva-user-token.reducer';
import { registerLocaleData } from '@angular/common';
import localEs from '@angular/common/locales/es';

registerLocaleData(localEs, 'es');

@NgModule({
    declarations: [AppComponent],
    imports: [
        AuthDataModule.forRoot({
            mechanism: 'ezy',
        }),
        StoreModule.forRoot(
            {
                auth: AuthReducer,
                count: CounterReducer,
                translations: TranslationReducer,
                currentProgress: CurrentProgressReducer,
                purchasePersistence: PurchasePersistenceReducer,
                modalError: ModalErrorReducer,
                configuration: ConfigurationReducer,
                loadingUser: LoadingUserReducer,
                refreshData: RefreshDataReducer,
                fromSSO: FromSSOReducer,
                catchErrorRestore: CatchErrorReducer,
                state: StateReducer,
                etoken: EtokenReducer
            },
            {
                metaReducers: [
                    hydrationMetaReducer,
                    vivaUserTokenMetaReducer,
                    vivaUserTokenExpirationMetaReducer,
                    vivaUserEsidMetaReducer,
                    vivaUserEtokenMetaReducer
                ],
            },
        ),
        TranslationsDataModule,
        ModalErrorDataModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        RecaptchaV3Module,
        TranslateModule.forRoot({
            defaultLanguage: 'es-mx',
        }),
        HttpClientModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
        NgSelectModule,
        FormsModule,
        SharedModule,
        CardDesignsDataModule,
        BrowserAnimationsModule,
        MatNativeDateModule,
        CardRedeemDataModule,
        OrdersDataModule,
        GtmDataModule,
        OrdersReceivedDataModule,
        OrdersCompensationDataModule,
    ],
    providers: [
        AuthInterceptorProvider,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LdGn1YpAAAAAAA4r6obHY4MtjhE75e_mFA4DrBl',
        },
        { provide: LOCALE_ID, useValue: 'es-mx' },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GlobalHttpErrorInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
