<h2 class="card-amount-title fw-bold">
    {{ 'vg_landing_purchase_amount_h2_title' | translate }}
</h2>
<div class="gc-amount" [@fadeInOut]>
    <vg-card>
        <div class="col-12 container-all">
            <div class="col-12 first d-flex flex-column price-btns">
                <div
                    class="d-flex align-self-center flex-row align-items-center"
                >
                    <button
                        class="btn-circle-minus d-flex justify-content-center align-items-center border-0 rounded-circle"
                        (click)="decreaseAmount(step)"
                        [ngClass]="{ disabled: cardAmount === min }"
                    >
                        <img
                            [src]="'vg/images/minus.svg' | cdn"
                            alt=""
                            class="img-minus m-auto"
                        />
                    </button>
                    <span class="monto fw-bold">{{
                        cardAmount | formatNumber
                    }}</span>
                    <button
                        class="btn-circle-plus d-flex justify-content-center align-items-center border-0 rounded-circle"
                        (click)="increaseAmount(step)"
                        [ngClass]="{ disabled: cardAmount === max }"
                    >
                        <img
                            [src]="'vg/images/plus.svg' | cdn"
                            alt=""
                            class="img-plus m-auto"
                        />
                    </button>
                </div>
                <p class="align-self-center desc-monto">
                    {{ 'vg_landing_purchase_amount_p_limit' | translate }}
                    {{ max | formatNumber }}
                </p>
            </div>
            <div class="d-flex row justify-content-between amounts-btns">
                <vg-button
                    class="w-50 d-grid btn-left"
                    [ngClass]="{ inactive: amount !== cardAmount }"
                    *ngFor="let amount of amounts$"
                    (click)="setAmount(amount)"
                >
                    {{ amount | formatNumber }}
                </vg-button>
            </div>
            <div class="customizeBtnContainer">
                <ng-container
                    *ngIf="'/purchase/send' | formatUrl | async as formattedUrl"
                >
                    <vg-button
                        [style]="'success-outline'"
                        class="w-100 d-grid"
                        (click)="onClickNextAndNextStep(formattedUrl)"
                    >
                        {{ 'vg_landing_purchase_amount_btn_next' | translate }}
                    </vg-button>
                </ng-container>
            </div>
        </div>
    </vg-card>
</div>
