import { checkScreenSize } from '@/common/helpers/screen-size.helper';
import { StepScrollService } from '@/common/step-scroll/step-scroll.service';
import { UrlService } from '@/common/url/url.service';
import { ValidationService } from '@/common/validation/validation.service';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { CardDesign } from '@/core/domain/configurations/models/configurations.model';
import { GetConfigParamsUseCase } from '@/core/domain/configurations/usecases/getConfigParams-loader.usecase';
import { PurchasePersistenceModel } from '@/core/domain/purchase-persistence/models/PurchasePersistence.model';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { SavePurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/save-purchase-persistence.usecase';
import { UserDetails } from '@/data/auth/services/ezy/responses/interfaces/user-details.interface';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { DateTime, DurationLike } from 'luxon';
import { switchMap } from 'rxjs';
import { ANIMATIONS_CARD } from '../card-amount/card-amount.component';

@Component({
    selector: 'vg-card-send',
    templateUrl: './card-send.component.html',
    styleUrls: ['./card-send.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class CardSendComponent implements OnInit {
    public isButtonEnabled = false;
    public recipientScheduleShippingVisible = false;
    public sendForm: FormGroup;
    public currentDate: Date;
    public minDate: Date;
    public maxDate: Date;

    @Output() newItemEvent = new EventEmitter();

    @Input() setCard: CardDesign | null = null;

    public userInfo: UserDetails | null = null;

    public mismatchingValueReference: ValidatorFn | null = null;

    private purchasePersistence: PurchasePersistenceModel | null = null;

    public enabledBtnNext = false;

    public defaultDcheduledDate = DateTime.now().toISO();

    private isMobileOrTablet?: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        protected readonly urlService: UrlService,
        private readonly savePurchasePersistence: SavePurchaseUseCase,
        private readonly getPurchasePersistence: GetPurchaseUseCase,
        private readonly validation: ValidationService,
        private readonly authCheckLogin: CheckLoginUseCase,
        private readonly config: GetConfigParamsUseCase<DurationLike>,
        private readonly stepScrollService: StepScrollService,
    ) {
        this.currentDate = new Date();
        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setDate(this.minDate.getDate() + 1);
        this.currentDate.setDate(this.currentDate.getDate() + 1);
        this.maxDate.setDate(this.maxDate.getDate() + 30);
        this.sendForm = this.formBuilder.group({
            recipientName: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.minLength(2),
                    Validators.maxLength(20),
                    this.forbiddenCharactersValidator(/["´?.¡-]/),
                ]),
            ],
            recipientEmail: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(
                        '^(?=.{1,63}@)[a-zA-Z0-9_-]{1,63}([.][a-zA-Z0-9_-]{0,62})?@[a-zA-Z0-9_]{1,63}([.][a-zA-Z0-9_]+)*[.][a-zA-Z]{2,63}$',
                    ),
                ]),
            ],
            scheduleShipping: [false, []],
            recipientScheduleShipping: ['', []],
        });
        // Validation for schedule shipping after check
        this.sendForm
            .get('scheduleShipping')
            ?.valueChanges.subscribe((scheduleShippingValue) => {
                const scheduleShippingValidation = scheduleShippingValue
                    ? Validators.compose([
                          Validators.required,
                          Validators.maxLength(17),
                      ])
                    : [];
                this.sendForm
                    .get('recipientScheduleShipping')
                    ?.setValidators(scheduleShippingValidation);
                this.sendForm
                    .get('recipientScheduleShipping')
                    ?.updateValueAndValidity();
            });
        // Validation for recipientName make first letter of each word capital
        this.sendForm.get('recipientName')?.valueChanges.subscribe((value) => {
            if (typeof value === 'string' && value.length > 0) {
                const capitalizedValue = value
                    .toLowerCase()
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                this.sendForm.patchValue(
                    { recipientName: capitalizedValue },
                    { emitEvent: false },
                );
            }
        });
        this.sendForm
            .get('scheduleShipping')
            ?.valueChanges.subscribe((value) =>
                this.onChangeValueScheduleShipping(value),
            );
        this.initGetInfoUser();
    }
    ngOnInit(): void {
        this.initPurchasePersistence();
        this.initGetDefaultDcheduledDate();
        this.isMobileOrTablet = checkScreenSize();
    }

    public onChangeValueScheduleShipping(value: unknown): void {
        if (
            typeof this.userInfo?.email !== 'string' ||
            this.userInfo?.email.length === 0
        ) {
            this.enabledBtnNext = true;
            return;
        }
        if (value === true) {
            this.addMitmachedValidator();
        } else {
            this.removeMitmachedValidator();
        }
        this.enabledBtnNext = true;
    }

    public initGetDefaultDcheduledDate(): void {
        this.config
            .execute('defaultDcheduledDate')
            .subscribe((responseDurationLike: DurationLike) => {
                this.defaultDcheduledDate = DateTime.now()
                    .plus(responseDurationLike)
                    .toISO();
            });
    }

    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                this.purchasePersistence = purchasePersistence;
                this.sendForm
                    .get('recipientName')
                    ?.setValue(purchasePersistence?.shipment?.recipientName);
                this.sendForm
                    .get('recipientEmail')
                    ?.setValue(purchasePersistence?.shipment?.recipientEmail);
                this.sendForm
                    .get('scheduleShipping')
                    ?.setValue(purchasePersistence?.shipment?.scheduleShipping);
                if (
                    purchasePersistence?.shipment?.recipientScheduleShipping &&
                    this.sendForm.get('scheduleShipping')?.getRawValue()
                ) {
                    this.currentDate = DateTime.fromISO(
                        purchasePersistence.shipment.recipientScheduleShipping,
                    ).toJSDate();
                }
                if (
                    typeof purchasePersistence?.shipment?.recipientEmail ===
                        'string' &&
                    purchasePersistence?.shipment?.recipientEmail.length > 0
                ) {
                    this.sendForm.get('recipientEmail')?.markAsTouched();
                }
                if (purchasePersistence?.shipment?.scheduleShipping !== true) {
                    this.enabledBtnNext = true;
                }
            });
    }
    onSubmit(formattedUrl: string) {
        if (this.sendForm.valid && this.enabledBtnNext) {
            const sendFromData = this.sendForm.value;
            if (!this.sendForm.get('scheduleShipping')?.getRawValue()) {
                sendFromData.recipientScheduleShipping =
                    this.defaultDcheduledDate;
            }
            this.savePurchasePersistence
                .execute({
                    shipment: sendFromData,
                })
                .pipe(
                    switchMap(() => {
                        return this.savePurchasePersistence.execute({
                            cardDesign: this.setCard!,
                        });
                    }),
                )
                .subscribe(() => this.newItemEvent.emit());
            this.stepScrollService.nextStep(
                formattedUrl,
                'scroll',
                this.isMobileOrTablet,
            );
        }
    }
    // Validation for prohibited characters in recipientName
    forbiddenCharactersValidator(forbiddenChars: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = forbiddenChars.test(control.value);
            return forbidden
                ? { forbiddenChars: { value: control.value } }
                : null;
        };
    }

    private removeMitmachedValidator(): void {
        this.sendForm
            .get('recipientEmail')
            ?.removeValidators(this.mismatchingValueReference!);
        this.sendForm.get('recipientEmail')?.updateValueAndValidity();
    }

    private addMitmachedValidator(): void {
        this.mismatchingValueReference = this.validation.mismatchingValue(
            this.userInfo?.email as string,
        );
        this.sendForm
            .get('recipientEmail')
            ?.addValidators(this.mismatchingValueReference);
        this.sendForm.get('recipientEmail')?.updateValueAndValidity();
    }

    private initGetInfoUser(): void {
        this.authCheckLogin.execute().subscribe({
            next: (auth) => {
                if (auth) {
                    this.userInfo = auth.details;
                    this.onChangeValueScheduleShipping(
                        this.purchasePersistence?.shipment?.scheduleShipping,
                    );
                }
            },
            error: () => (this.enabledBtnNext = true),
        });
    }
}
