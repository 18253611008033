import { GlobalState } from '@/core/domain/persistence/models/global-state.model';
import { resetToken, updateToken } from '@/data/auth/store/auth.actions';
import { ActionReducer } from '@ngrx/store';

const LOCAL_STORAGE_VIVA_USER_ESID_STATE_KEY = 'viva-user-esid';

export const vivaUserEsidMetaReducer = (
    reducer: ActionReducer<GlobalState>,
): ActionReducer<GlobalState> => {
    return (state, action) => {
        const nextState = reducer(state, action);

        if (action.type === updateToken.type) {
            const { esid } = nextState.auth;

            if(esid)
            localStorage.setItem(
                LOCAL_STORAGE_VIVA_USER_ESID_STATE_KEY,
                esid,
            );
        } else if (action.type === resetToken.type) {
            localStorage.removeItem(LOCAL_STORAGE_VIVA_USER_ESID_STATE_KEY);
        }

        return nextState;
    };
};
