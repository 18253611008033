import { CardDesign } from '@/core/domain/configurations/models/configurations.model';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GetConfigParamsUseCase } from '../../../../core/domain/configurations/usecases/getConfigParams-loader.usecase';

@Component({
    selector: 'vg-swiper-giftcard-design',
    templateUrl: './vg-swiper-giftcard-design.component.html',
    styleUrls: ['./vg-swiper-giftcard-design.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VgSwiperGiftcardDesignComponent implements OnInit {
    @Input() breakpoints = {
        1: {
            spaceBetween: '5px',
        },
        576: {
            spaceBetween: '14px',
        },
    };

    @Output() imgChange = new EventEmitter<string>();

    @Output() selectedCard = new EventEmitter<CardDesign>();

    protected setCard: CardDesign | null = null;

    cards$ = new BehaviorSubject<CardDesign[]>([]);
    showSwiper = false;

    currentSlideIndex = 0;

    public initialSlide = 0;

    constructor(
        private readonly getCardDesigns: GetConfigParamsUseCase<CardDesign[]>,
        private readonly getPurchasePersistence: GetPurchaseUseCase,
    ) {}
    ngOnInit(): void {
        this.initCardDesigns();
    }

    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                if (purchasePersistence?.cardDesign) {
                    this.setCard = purchasePersistence.cardDesign;
                    this.selectedCard.emit(this.setCard);
                    this.imgChange.emit(this.setCard.resource);
                    this.cards$
                        .getValue()
                        .forEach((cardDesign: CardDesign, index: number) => {
                            if (cardDesign.id === this.setCard?.id) {
                                this.initialSlide = index;
                            }
                            this.showSwiper = true;
                        });
                } else {
                    this.emitCardByPosition(0);
                    this.showSwiper = true;
                }
            });
    }

    public identify(index: number, item: any) {
        return item.id;
    }

    public handleImageChange(slideIndex: number) {
        this.currentSlideIndex = slideIndex;
        this.emitCardByPosition(slideIndex);
    }

    protected emitCardByPosition(position: number): void {
        const cardList = this.cards$.getValue();
        const card = cardList[position];
        this.selectedCard.emit(card);
        this.imgChange.emit(card.resource);
    }

    private initCardDesigns(): void {
        this.getCardDesigns.execute('cardDesigns').subscribe((data) => {
            this.cards$.next(data);
            this.initPurchasePersistence();
        });
    }
}
