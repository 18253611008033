<div class="payment-card" [@fadeInOut]>
    <vg-card>
        <h3 class="title">{{ 'vg_purchase_payment_h3_title' | translate }}</h3>
        <img class="payments" [src]="'vg/images/payments.svg' | cdn" alt="" />
        <div class="container container-payment px-0">
            <form [formGroup]="paymentForm">
                <div class="row payment-data">
                    <div class="col-12 col-xl-5">
                        <vg-input
                            [(ngModel)]="paymentInformation.cardNumber"
                            [label]="
                                'vg_purchase_payment_vg_input_label_credit-card'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_credit-card'
                                    | translate
                            "
                            [mask]="
                                cardSchema.scheme === 'american-express'
                                    ? '0000 000000 00000'
                                    : '0000 0000 0000 0000'
                            "
                            [type]="'card'"
                            (cardSchema)="onGetCardSchema($event)"
                            formControlName="cardNumber"
                            [flowPage]="'purchase_payment'"
                            [autocomplete]="'cc-number'"
                            [inputmode]="'numeric'"
                        ></vg-input>
                    </div>
                    <div class="col-12 col-xl-7">
                        <div class="row">
                            <div class="col-6 date">
                                <vg-input
                                    [label]="
                                        'vg_purchase_payment_vg_input_label_expiration-date'
                                            | translate
                                    "
                                    [placeholder]="
                                        'vg_purchase_payment_vg_input_placeholder_expiration-date'
                                            | translate
                                    "
                                    [type]="'expirationDate'"
                                    [mask]="'00/00'"
                                    formControlName="expirationDate"
                                    [flowPage]="'purchase_payment'"
                                    [inputmode]="'numeric'"
                                ></vg-input>
                            </div>
                            <div class="col-6 cvv">
                                <vg-input
                                    [label]="
                                        'vg_purchase_payment_vg_input_label_cvv'
                                            | translate
                                    "
                                    [placeholder]="
                                        'vg_purchase_payment_vg_input_placeholder_cvv'
                                            | translate
                                    "
                                    [mask]="'0000'"
                                    formControlName="cvv"
                                    [flowPage]="'purchase_payment'"
                                    [type]="'tel'"
                                    [inputmode]="'numeric'"
                                ></vg-input>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row payment-title">
                    <div class="col-12">
                        {{
                            'vg_purchase_payment_div_purchase-confirmation-title'
                                | translate
                        }}
                    </div>
                </div>
                <div class="row billing-data">
                    <div class="col-12 col-lg-6">
                        <vg-input
                            [label]="
                                'vg_purchase_payment_vg_input_label_name'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_name'
                                    | translate
                            "
                            formControlName="firstName"
                            [flowPage]="'purchase_payment'"
                        ></vg-input>
                    </div>
                    <div class="col-12 col-lg-6">
                        <vg-input
                            [label]="
                                'vg_purchase_payment_vg_input_label_lastname'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_lastname'
                                    | translate
                            "
                            formControlName="lastName"
                            [flowPage]="'purchase_payment'"
                        ></vg-input>
                    </div>
                    <div class="col-12">
                        <vg-input
                            [label]="
                                'vg_purchase_payment_vg_input_label_address'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_address'
                                    | translate
                            "
                            formControlName="address"
                            [flowPage]="'purchase_payment'"
                        ></vg-input>
                    </div>
                    <div class="col-12 col-lg-4">
                        <vg-input
                            [label]="
                                'vg_purchase_payment_vg_input_label_city'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_city'
                                    | translate
                            "
                            formControlName="city"
                            [flowPage]="'purchase_payment'"
                        ></vg-input>
                    </div>
                    <div class="col-12 col-lg-4">
                        <vg-input
                            [label]="
                                'vg_purchase_payment_vg_input_label_state'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_state'
                                    | translate
                            "
                            [isSelect]="true"
                            [(ngModel)]="state"
                            [formControlName]="'state'"
                            [flowPage]="'purchase_payment'"
                            [items]="states"
                        ></vg-input>
                    </div>
                    <div class="col-12 col-lg-4">
                        <vg-input
                            [label]="
                                'vg_purchase_payment_vg_input_label_zipcode'
                                    | translate
                            "
                            [placeholder]="
                                'vg_purchase_payment_vg_input_placeholder_zipcode'
                                    | translate
                            "
                            [mask]="'00000'"
                            formControlName="postalCode"
                            [flowPage]="'purchase_payment'"
                            [type]="'tel'"
                            [inputmode]="'numeric'"
                        ></vg-input>
                    </div>
                </div>
            </form>
        </div>
    </vg-card>
</div>
