export const environment = {
    production: false,
    productRootPath: 'vivagift',
    cdn: 'https://cdn-viva.gruposti.dev/test',
    allowedLanguages: ['es-mx', 'en-us'],
    channel: 'web',
    providerType: 'VB',
    SSO: {
        BASE_HOST: 'https://auth-api-gw-test.doters.io/v2',
        ENDPOINTS: {
            getUserInfo: '/user',
            refreshToken: '/token',
            loginExternal: '/account/loginexternal',
        },
        PARTNER: {
            host: 'https://auth-test.doters.io/v2',
            clientId: 'viva-web',
            clientSecret: 'GlMTbnwjRA',
            language: 'es-MX',
            redirectUri: 'https://vivagift-test.gruposti.dev',
            state: '12345',
        },
    },
    recurly: {
        publicKey: 'ewr1-rrjCHc5hCrpMQWOtmB2SPt',
    },
    vivaApi: {
        API_KEY: 'g6qR7yMnoJaq8jdgfG4Sk5CzMrblu68NaffUFk4p',
        BASE_HOST: 'https://api-gw-test.vivaaerobus.io/v1',
        ENDPOINTS: {
            loginExternal: '/account/loginexternal',
            restore: '/account/restore',
        },
    },
    vgApi: {
        BASE_HOST: 'https://api-vivagift-gw-test.gruposti.dev/v2',
        ENDPOINTS: {
            createPurchaseCustom: '/giftcard/purchase/custom',
            getCompensations: '/giftcard/compensation',
            getPDF: '/giftcard/pdf/',
            getPurchased: '/giftcard/purchased',
            getReceived: '/giftcard/received',
            redeem: '/giftcard/redeem',
            redeemB2B: '/b2b/redeem',
            redeemMP: '/mp/redeem',
        },
    },
};