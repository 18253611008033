import { UrlService } from '@/common/url/url.service';
import { getLanguage } from '@/common/utils/strings/path.utils';
import { CheckLoginUseCase } from '@/core/domain/auth/usecases/check-login.usecase';
import { LoginUseCase } from '@/core/domain/auth/usecases/login.usecase';
import { GetConfigParamsUseCase } from '@/core/domain/configurations/usecases/getConfigParams-loader.usecase';
import { inject } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    ResolveFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { first, forkJoin, of, switchMap } from 'rxjs';

export const CompensationResolver: ResolveFn<unknown> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
) => {
    const qp = state.root.queryParams;
    const ParamKeys = Object.keys(qp);

    const normalizedQp = Object.fromEntries(
        Object.entries(qp).map(([key, value]) => [key.toLowerCase(), value]),
    );

    if (ParamKeys.length === 0) return of(null);
    if (!Object.keys(normalizedQp).includes('referer')) return of(null);

    const router = inject(Router);
    const authCheckLogin = inject(CheckLoginUseCase);
    const authLogin = inject(LoginUseCase);
    const translateRoutes = inject(UrlService);
    const config = inject(GetConfigParamsUseCase);

    return config.execute('referers').pipe(
        switchMap((refer) => {
            const referCompensation = refer.find((item: any) => {
                return Object.keys(item).includes('compensations');
            });
            if (referCompensation?.compensations !== normalizedQp['referer']) {
                return router
                    .navigate(['/'], {
                        queryParams: {},
                        queryParamsHandling: 'merge',
                        replaceUrl: true,
                    })
                    .then(() => of(null));
            }
            return of(null);
        }),
        switchMap(() => {
            return forkJoin([
                translateRoutes.format('dashboard/compensations').pipe(first()),
                authCheckLogin.execute().pipe(first()),
            ]);
        }),
        switchMap(([path, auth]) => {
            const toPath = path.replace('/' + getLanguage() + '/vivagift/', '');

            if (!auth) {
                authLogin.execute({
                    path: toPath.startsWith('/')
                        ? toPath.replace('/', '')
                        : toPath,
                });
                return of(null);
            }

            return router
                .navigate(path.split('/'), {
                    queryParams: {},
                    queryParamsHandling: 'merge',
                    replaceUrl: true,
                })
                .then(() => of(null));
        }),
    );
};
