<vg-card-giftcard [img]="card?.image">
    <div class="container-summary">
        <div class="to">
            {{ 'vg_landing_purchase_confirmation_ready-to-text' | translate }}
            {{ card?.recipient }}
        </div>
        <div class="from">
            {{ 'vg_landing_purchase_confirmation_ready-from-text' | translate }}
            {{ card?.gifter }}
        </div>
        <div class="code">
            {{ 'vg_landing_purchase_confirmation_ready-code-text' | translate }}
        </div>
        <div
            class="code-container w100 d-flex justify-content-center align-items-center"
        >
            <span class="font-family-lato">{{
                card?.id | mask: 'AAAAA-AAAAA-AAAA'
            }}</span>
        </div>
        <div class="message font-family-lato">
            {{ card?.message }}
        </div>
    </div>
</vg-card-giftcard>
