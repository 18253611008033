import { checkScreenSize } from '@/common/helpers/screen-size.helper';
import { StepScrollService } from '@/common/step-scroll/step-scroll.service';
import { UrlService } from '@/common/url/url.service';
import { CardDesign } from '@/core/domain/configurations/models/configurations.model';
import { GtmPersonalizeGift } from '@/core/domain/gtm/models/gtm-personalize-gift.model';
import { SetDataLayerParamsUseCase } from '@/core/domain/gtm/usecases/setDataLayer.usecase';
import { GetPurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/get-purchase-persistence.usecase';
import { SavePurchaseUseCase } from '@/core/domain/purchase-persistence/usecases/save-purchase-persistence.usecase';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { switchMap } from 'rxjs';
import { ANIMATIONS_CARD } from '../card-amount/card-amount.component';

@Component({
    selector: 'vg-card-message',
    templateUrl: './card-message.component.html',
    styleUrls: ['./card-message.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: ANIMATIONS_CARD,
})
export class CardMessageComponent {
    public messageForm: FormGroup;
    messageValue = '';
    @Input() setCard: CardDesign | null = null;
    @Input() price: string | undefined = '';
    @Input() date: string | undefined = '';

    private isMobileOrTablet?: boolean;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly savePurchasePersistence: SavePurchaseUseCase,
        private readonly getPurchasePersistence: GetPurchaseUseCase,
        private readonly gtmDataLayer: SetDataLayerParamsUseCase,
        private readonly stepScrollService: StepScrollService,
        protected readonly url: UrlService,
    ) {
        this.messageForm = this.formBuilder.group({
            message: ['', Validators.compose([Validators.maxLength(45)])],
            from: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.maxLength(45),
                ]),
            ],
        });
        this.initPurchasePersistence();
        this.isMobileOrTablet = checkScreenSize();
    }
    public onClickSubmitMessage(formattedUrl: string): void {
        if (!this.messageForm.valid) {
            return;
        }
        this.savePurchasePersistence
            .execute({
                message: this.messageForm.value,
            })
            .pipe(
                switchMap(() => {
                    return this.savePurchasePersistence.execute({
                        cardDesign: this.setCard!,
                    });
                }),
            )
            .subscribe(() => this.onSavePurchasePersistence(formattedUrl));
    }
    public onSavePurchasePersistence(formattedUrl: string): void {
        const scrollToTop = true;
        this.stepScrollService.nextStep(
            formattedUrl,
            'scroll',
            this.isMobileOrTablet,
            scrollToTop
        );
    }
    public initPurchasePersistence(): void {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                this.messageValue = purchasePersistence?.message?.message;
                this.messageForm
                    .get('from')
                    ?.setValue(purchasePersistence?.message?.from);
            });
    }

    messageSwiper(message: string) {
        this.gtmActionMessage('add_suggested_message');
        this.messageValue = message;
    }

    showSwiper(): string {
        return this.messageValue ? 'no-show' : 'show';
    }

    clickMessage() {
        this.gtmActionMessage('add_personalized_message');
    }

    clickName() {
        this.gtmActionMessage('add_name');
    }

    gtmActionMessage(
        label:
            | 'add_name'
            | 'add_personalized_message'
            | 'add_suggested_message',
    ) {
        this.getPurchasePersistence
            .execute()
            .subscribe((purchasePersistence) => {
                const dt = DateTime.fromISO(
                    purchasePersistence?.shipment?.recipientScheduleShipping
                        ? purchasePersistence?.shipment
                              ?.recipientScheduleShipping
                        : DateTime.local().toISODate(),
                );
                this.gtmDataLayer.execute<GtmPersonalizeGift>({
                    event: 'ev_viva_gift_personalize_card',
                    category: 'viva_gift',
                    action: 'personalize_gift',
                    label: label,
                    send_date: dt.toFormat('dd/MM/yyyy'),
                    gift_amount: '' + purchasePersistence?.amount,
                });
            });
    }
}
