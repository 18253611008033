import { createReducer, on } from '@ngrx/store';
import * as actions from './etoken.actions';

export const updateEtokenRestoreReducer = (
    _: string,
    { etoken }: { etoken: string },
): string => etoken;

export const EtokenReducer = createReducer(
    '',
    on(actions.updateEtoken, updateEtokenRestoreReducer),
    on(actions.resetEtoken, (): string => ''),
);
