import {
    getTimeStampPlusExpiration,
    isTimestampOnTime,
} from '@/common/utils/strings/time.utils';
import { UserDetails } from '../../services/ezy/responses/interfaces/user-details.interface';
import { LoginExternalData } from '../../services/ezy/responses/login-external.response';

export class AuthUserData {
    isLogged: boolean;
    authToken: string;
    accessToken: string;
    session?: string;
    refreshToken?: string;
    providerType?: string;
    expiration: number;
    details: UserDetails;
    esid?: string;

    constructor(data: LoginExternalData) {
        this.accessToken = data.authTokenExternal;
        this.authToken = data.authToken;
        this.session = data?.sessionIdExternal;
        this.refreshToken = '';
        this.expiration = getTimeStampPlusExpiration(data.tokenExpirationTime);
        this.details = data.userDetails;
        this.isLogged = isTimestampOnTime(this.expiration);
        this.providerType = data.providerType;
        this.esid = data.sessionIdExternal;
    }
}
