import { GlobalState } from '@/core/domain/persistence/models/global-state.model';
import { resetEtoken, updateEtoken } from '@/data/auth/store/vivaUserEtoken/etoken.actions';
import { ActionReducer } from '@ngrx/store';

export const LOCAL_STORAGE_VIVA_USER_ETOKEN_STATE_KEY = 'viva-user-etoken';
 
export const vivaUserEtokenMetaReducer = (
    reducer: ActionReducer<GlobalState>,
): ActionReducer<GlobalState> => {
    return (state, action) => {
        const nextState = reducer(state, action);

        if (action.type === updateEtoken.type) {
            const { etoken } = nextState;
            localStorage.setItem(
                LOCAL_STORAGE_VIVA_USER_ETOKEN_STATE_KEY,
                etoken,
            );
        } else if (action.type === resetEtoken.type) {
            localStorage.removeItem(LOCAL_STORAGE_VIVA_USER_ETOKEN_STATE_KEY);
        }

        return nextState;
    };
};