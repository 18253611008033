import { setState } from '@/data/auth/store/state.actions';
import { environment as env } from '@/env/environment';
import { Store } from '@ngrx/store';
import { getLanguage } from '../strings/path.utils';

interface PartnerRequestConfig {
    redirectUri?: string;
    state?: string;
    go_to_page?: string;
}

export const getPartnerRequest = (
    store: Store,
    config?: PartnerRequestConfig,
) => {
    const HOST = env.SSO.PARTNER.host;

    const generatedState = generate6CharString();
    store.dispatch(setState({ state: generatedState }));

    const params = {
        clientId: env.SSO.PARTNER.clientId,
        clientSecret: env.SSO.PARTNER.clientSecret,
        language: getLanguagePartner() ?? env.SSO.PARTNER.language,
        redirectUri: config?.redirectUri ?? env.SSO.PARTNER.redirectUri,
        state: generatedState,
    };

    const queryParams = new URLSearchParams(params).toString();

    return `${HOST}?${queryParams}`;
};

function generate6CharString() {
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';

    // Create a typed array of random values
    const array = new Uint8Array(6);
    window.crypto.getRandomValues(array);

    for (let i = 0; i < 6; i++) {
        const randomIndex = array[i] % characters.length;
        result += characters[randomIndex];
    }

    return result;
}

const getLanguagePartner = () => {
    const language = getLanguage().split('-');
    const languagePartner = `${language[0]}-${language[1].toLocaleUpperCase()}`;
    return languagePartner;
};

const getPostLogOutUri = () => {
    const HOST = env.SSO.PARTNER.redirectUri;
    const language = getLanguage();
    const defaultPage = 'vivagift';

    return [HOST, language, defaultPage].join('/');
};

export const getLogoutUri = () => {
    const HOST = env.SSO.PARTNER.host;
    const postLogOutUri = getPostLogOutUri();

    const params = {
        post_logout_redirect_uri: postLogOutUri,
        client_id: env.SSO.PARTNER.clientId,
    };

    const queryParams = new URLSearchParams(params).toString();

    return `${HOST}/logout?${queryParams}`;
};
